// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-box-intro-js": () => import("./../../src/pages/box_intro.js" /* webpackChunkName: "component---src-pages-box-intro-js" */),
  "component---src-pages-box-service-js": () => import("./../../src/pages/box_service.js" /* webpackChunkName: "component---src-pages-box-service-js" */),
  "component---src-pages-crm-demo-h-5-js": () => import("./../../src/pages/crm_demo_h5.js" /* webpackChunkName: "component---src-pages-crm-demo-h-5-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-upcamp-guide-l-1-index-js": () => import("./../../src/pages/upcamp/guide-L1/index.js" /* webpackChunkName: "component---src-pages-upcamp-guide-l-1-index-js" */),
  "component---src-pages-upcamp-guide-l-2-index-js": () => import("./../../src/pages/upcamp/guide-L2/index.js" /* webpackChunkName: "component---src-pages-upcamp-guide-l-2-index-js" */),
  "component---src-pages-upcamp-listen-index-js": () => import("./../../src/pages/upcamp/listen/index.js" /* webpackChunkName: "component---src-pages-upcamp-listen-index-js" */),
  "component---src-pages-zl-js": () => import("./../../src/pages/zl.js" /* webpackChunkName: "component---src-pages-zl-js" */)
}

